@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* In your global CSS file */
.cs-message--incoming .cs-message__content {
  @apply bg-robylon-gray-light !important;
}

.cs-message--outgoing .cs-message__content {
  @apply bg-robylon-blue;
  @apply text-white !important;
}

.cs-message-input__content-editor-wrapper {
  @apply bg-white !important;
}
.cs-message-input__content-editor-container {
  @apply bg-white !important;
}

.cs-message-input__content-editor {
  @apply bg-transparent !important;
}

.cs-chat-container .cs-message-input {
  @apply border-t border-t-robylon-gray-light !important;
}

.cs-main-container {
  @apply border-robylon-gray-light !important;
  @apply rounded-[8px];
}

.message-error {
  @apply bg-robylon-error;
}

.form-back .cs-button--arrow {
  color: black;
  @apply text-md;
}

.cs-conversation-header__info {
  color: rgba(52, 58, 64, 0.56) !important;
}

.cs-conversation-header__user-name {
  @apply text-[#343A40] !important;
}

.cs-button.cs-button--right.cs-button--send {
  min-width: 61px;
  min-height: 41px;
  align-self: auto;
}

.cs-message-input__tools {
  align-items: "center";
}
